function HomePortfolio() {
  const {
    portfolio: { heading }
  } = useHomeData()
  const items = usePortfolioData()

  const itemsMachine = filterAndLimitMachine.withContext({
    ...filterAndLimitMachine.context,
    category: 'stage',
    limitsByScreen: { xl: 3, md: 4, xs: 3 }
  })
  const [state, send] = useMachine(itemsMachine)
  useRecalculateLimit(state, send)

  const itemsInCategory = useMemo(
    () => filterItemsByCategory(state, items),
    [state, items]
  )

  const visibleItems = useMemo(() => limitItems(state, itemsInCategory), [
    state,
    itemsInCategory
  ])

  const limited = useMemo(() => state.context.limit < itemsInCategory.length, [
    state.context.limit,
    itemsInCategory.length
  ])

  return (
    <Section id="portfolio">
      <Heading heading={heading} />

      <Filters category={state.context.category} send={send} />

      {visibleItems && (
      <Items
        visibleItems={visibleItems}
        limited={limited}
        previousLimit={state.context.previousLimit}
      />
      )}

      {limited && (
        <ViewAll onClick={() => send('VIEW_ALL')} aria-expanded={false}>
          View all {state.context.category} productions
        </ViewAll>
      )}
    </Section>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Section = styled.section`
  ${container}
  padding-top: ${p => p.theme.s8};
  padding-bottom: ${p => p.theme.s8};

  ${media.md`
    padding-top: ${p => p.theme.s9};
    padding-bottom: ${p => p.theme.s9};
  `}
`

const ViewAll = styled.button`
  ${underlineThatGrowsOnHover}
  margin-top: ${p => p.theme.s7};
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.f4};
  font-weight: ${p => p.theme.fw2};
  text-transform: uppercase;
`

///////////////////////////////////////////////////////////////////////////////////

function Filters({ category, send }) {
  return (
    <Toolbar role="toolbar" aria-label="filter options" aria-controls="items">
      <Filter value="stage" category={category} send={send} />
      <Filter value="film" category={category} send={send} />
      <Filter value="in development" category={category} send={send} />
    </Toolbar>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Toolbar = styled.div`
  padding-top: ${p => p.theme.s6};
`

///////////////////////////////////////////////////////////////////////////////////

function Filter({ value, category, send }) {
  return (
    <Button
      value={value}
      onClick={() => send({ type: 'CHANGE_CATEGORY', category: value })}
      aria-pressed={category === value}
    >
      {value}
    </Button>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Button = styled.button`
  ${underlineThatGrowsOnHover}

  margin-right: ${p => p.theme.s6};
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.f6};
  font-weight: ${p => p.theme.fw2};
  text-transform: uppercase;

  &[aria-pressed='true'] {
    &:after {
      height: 0.75em;
    }
  }
`

///////////////////////////////////////////////////////////////////////////////////

function Items({ visibleItems, limited, previousLimit }) {
  return (
    <List id="items">
      {visibleItems.map(({ node: item }, i) => (
        <LiWithCSSAnimation
          key={item.title.text}
          style={{
            animationDelay: limited
              ? `calc(${i * 100}ms)`
              : `calc(${previousLimit * -100}ms + ${i * 100}ms)`
          }}
        >
          <Photo
            fluid={{
              ...item.featuredImage.file.childImageSharp.fluid,
              aspectRatio: 8 / 5
            }}
            alt={item.featuredImage.alt}
          />

          <Title
            lang={item.title.lang}
            dangerouslySetInnerHTML={{ __html: item.title.text }}
          />
          <Company>{item.company}</Company>
          <Text dangerouslySetInnerHTML={{ __html: item.description }} />

          <MoreInfo>More Info</MoreInfo>

          <CardLink href={`/${item.slug}`}>
            <SrText>Read more detailed info about {item.title.text}</SrText>
          </CardLink>
        </LiWithCSSAnimation>
      ))}
    </List>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const List = styled.ul`
  ${media.md`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${p => p.theme.s6};
  `}

  ${media.xl`
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: ${p => p.theme.s7};
  `}
`

const LiWithCSSAnimation = styled.li`
  position: relative;
  margin-top: ${p => p.theme.s7};
  transform-origin: bottom;
  visibility: hidden;
  animation: ${enter} 1s cubic-bezier(0.5, 0, 0, 1) forwards;
`

const Photo = styled(Image)`
  margin-top: ${p => p.theme.s4};
  font-size: 1.7rem;
  transition: box-shadow 0.2s ease-in-out;

  ${LiWithCSSAnimation}:hover & {
    box-shadow: ${p => p.theme.shadow5};
  }
`

const Title = styled.h3`
  padding-top: ${p => p.theme.s4};
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.f6};
`

const Company = styled.p`
  padding-top: ${p => p.theme.s4};
  line-height: ${p => p.theme.lh1};
  font-family: ${p => p.theme.headingFont};
  font-size: 1.1rem;
  font-weight: ${p => p.theme.fw2};
  text-transform: uppercase;
  color: ${p => p.theme.orange};
`

const multilineTruncate = css`
  /* See: http://dropshado.ws/post/1015351370/webkit-line-clamp */
  /* See: https://stackoverflow.com/questions/38989475/css-multi-line-line-clamp-ellipsis-doesnt-work#comment94167650_53268674 */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  visibility: visible;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Text = styled.p`
  margin-top: ${p => p.theme.s4};
  ${copy}
  ${multilineTruncate}
`

const MoreInfo = styled.p`
  ${underlineThatGrowsOnHover}
  padding-top: ${p => p.theme.s3};
  font-family: ${p => p.theme.headingFont};
  font-weight: ${p => p.theme.fw2};
  text-transform: uppercase;

  ${LiWithCSSAnimation}:hover & {
    &:after {
      height: 0.75em;
    }
  }
`

const CardLink = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

///////////////////////////////////////////////////////////////////////////////////

import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import Image from 'gatsby-image'

import Heading from './Heading'
import { Link, SrText } from './elements'
import useHomeData from '../data/useHomeData'
import usePortfolioData from '../data/usePortfolioData'

import useMachine from '../logic/useMachine'
import { 
  filterAndLimitMachine, 
  useRecalculateLimit, 
  filterItemsByCategory,
  limitItems 
} from '../logic/filterAndLimit'

import { container, copy, enter, media, underlineThatGrowsOnHover } from '../styles'

export default HomePortfolio
