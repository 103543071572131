function Bottom() {
  function scrollToTop(e, id) {
    e.preventDefault()
    scrollToId(id)
  }

  return (
    <Footer>
      <ScrollUp href="#top" onClick={e => scrollToTop(e, `#top`)}>
        <Arrow aria-hidden="true" />
        Going up?
        <SrText>(scroll to the top of the page)</SrText>
      </ScrollUp>

      <Content>
        <Link href="/">
          <Logo aria-hidden="true" />
          <SrText>Navigate to the top of the home page</SrText>
        </Link>

        <Text>
          &copy; {new Date().getFullYear()} Alaina Viau.
          All&nbsp;rights&nbsp;reserved.
          <br />
          Brewed&nbsp;by&nbsp;
          <Agency href="http://coffeeshopcreative.ca">
            Coffeeshop&nbsp;Creative
          </Agency>
          .
        </Text>
      </Content>
    </Footer>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Footer = styled.footer`
  ${container}
  padding-top: ${p => p.theme.s8};
  padding-bottom: ${p => p.theme.s6};

  ${media.sm`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas: "content going-up";
  `}
`

const ScrollUp = styled(Link)`
  ${underlineThatGrowsOnHover}

  grid-area: going-up;
  align-self: end;
  display: flex;
  flex-direction: column;
  width: max-content;
  font-family: ${p => p.theme.headingFont};
  font-weight: 700;
  text-transform: uppercase;
`

const Content = styled.div`
  grid-area: content;
`

const Arrow = styled(ArrowSVG)`
  display: block;
  margin-bottom: ${p => p.theme.s1};
  min-width: 100%;
  height: 1.6rem;
  transition: transform 0.2s ease-in-out;

  ${ScrollUp}:hover & {
    transform: translateY(-0.35em);
  }
`

const Logo = styled(LogoSVG)`
  padding-top: ${p => p.theme.s7};
  width: 75%;
  max-width: 18rem;
`

const Text = styled.p`
  padding-top: ${p => p.theme.s5};
  ${copy}
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.f2};
  font-weight: 700;

  ${media.sm`
    padding-top: ${p => p.theme.s6};
  `}
`

const Agency = styled(Link)`
  ${underlineThatGrowsOnHover}
`

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import styled from 'styled-components'

import { Link, SrText } from './elements'
import scrollToId from '../logic/scrollToId'
import { ReactComponent as ArrowSVG } from '../svg/arrow.svg'
import { ReactComponent as LogoSVG } from '../svg/logo.svg'
import { container, copy, media, underlineThatGrowsOnHover } from '../styles'

export default Bottom
