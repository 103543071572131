// See: https://www.gatsbyjs.org/blog/2019-02-20-introducing-use-static-query/

function useContactData() {
  const { contactYaml } = useStaticQuery(
    graphql`
      query {
        contactYaml {
          heading {
            normal
            orange
            pronunciation {
              en
              ipa
            }
          }

          socials {
            heading
            text
            href
          }

          email {
            heading
            text
            href
          }

          form {
            heading
            invitation
          }
        }
      }
    `
  )

  return contactYaml
}

///////////////////////////////////////////////////////////////////////////////////

import { useStaticQuery, graphql } from 'gatsby'

export default useContactData

/*

import useContactData from '../data/useContactData'

const {heading, socials, email, form} = useContactData()

*/
