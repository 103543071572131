function NetlifyForm() {
  const [state, send] = useMachine(netlifyFormMachine)

  function handleChange(e) {
    send({ type: `UPDATE_FIELD`, name: e.target.name, value: e.target.value })
  }

  function handleSubmit(e) {
    e.preventDefault()
    send(`SUBMIT`)
  }

  return (
    <>
      {state.value !== `success` && (
        <form netlify="true" name="Contact" onSubmit={handleSubmit}>
          {/* Hidden fields required by Netlify */}
          <input type="hidden" name="form-name" value="Contact" />
          <input type="hidden" name="name" />
          <input type="hidden" name="email" />
          <textarea
            name="message"
            css={`
              display: none;
            `}
          />

          <Label htmlFor="name">Name*</Label>
          <Input
            id="name"
            name="name"
            type="text"
            aria-label="Name"
            placeholder="Peter Gelb"
            onChange={handleChange}
            required
          />

          <Label htmlFor="email">Email*</Label>
          <Input
            id="email"
            name="email"
            type="email"
            aria-label="Email address"
            placeholder="pgelb@metopera.org"
            onChange={handleChange}
            title={`The portion of the email address after the @ is invalid.`}
            pattern={emailRegex}
            required
          />

          <Label htmlFor="message">Message*</Label>
          <Textarea
            id="message"
            name="message"
            aria-label="Message"
            placeholder="Let's work together!"
            onChange={handleChange}
            rows="9"
            required
          />

          {state.value === `error` && (
            <AlertText>
              Oops! Something went wrong... Please confirm each field contains valid
              content and try again.
            </AlertText>
          )}

          <Submit type="submit">Send message</Submit>
        </form>
      )}

      {state.value === `success` && (
        <AlertText>
          Thanks for your message! Alaina will be in touch with you soon.
        </AlertText>
      )}
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Label = styled.label`
  display: block;
  padding-top: ${p => p.theme.s5};
  padding-bottom: ${p => p.theme.s1};
  font-family: ${p => p.theme.headingFont};
  font-weight: ${p => p.theme.fw2};
  text-transform: uppercase;
`

const emailRegex = `.+@.+..+`

const inputStyles = css`
  display: block;
  background: ${p => p.theme.lightOrange};
  padding: ${p => p.theme.s2};
  width: 100%;
  color: ${p => p.theme.black};

  &::placeholder {
    color: ${p => p.theme.black};
  }
`

const Input = styled.input`
  ${inputStyles}
`

const Textarea = styled.textarea`
  ${inputStyles}
  resize: vertical;
`

const AlertText = styled.p`
  display: block;
  margin: ${p => p.theme.s5} 0;
  background: ${p => p.theme.lightOrange};
  padding: ${p => p.theme.s2};
  line-height: 1.4;
`

const Submit = styled.button`
  ${underlineThatGrowsOnHover}
  
  margin-top: ${p => p.theme.s5};
  font-family: ${p => p.theme.headingFont};
  font-weight: ${p => p.theme.fw2};
  text-transform: uppercase;
  color: white;

  &:after {
    background-color: ${p => p.theme.black};
  }
`

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import styled, { css } from 'styled-components'

import useMachine from '../logic/useMachine'
import { netlifyFormMachine } from '../logic/netlifyForm'
import { underlineThatGrowsOnHover } from '../styles'

export default NetlifyForm
