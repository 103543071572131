function IndexPage() {
  return (
    <Base>
      <main id="main-content">
        <Hero />
        <Reviews />
        <About />
        <Portfolio />
        <Calendar />
        <Contact />
      </main>
      <Bottom />
    </Base>
  )
}

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'

import Base from '../ui/Base'
import Hero from '../ui/HomeHero'
import Reviews from '../ui/HomeReviews'
import About from '../ui/HomeAbout'
import Calendar from '../ui/HomeCalendar'
import Portfolio from '../ui/HomePortfolio'
import Contact from '../ui/Contact'
import Bottom from '../ui/Bottom'

export default IndexPage
