function HomeReviews() {
  const { reviews } = useHomeData()

  return (
    <Section>
      <h2>
        <SrText>Featured Reviews</SrText>
      </h2>

      <ul>
        {reviews.map((review, i) => (
          <Item key={review.quote} inner={i === 1}>
            <blockquote>
              <Quote
                dangerouslySetInnerHTML={{ __html: review.quote }}
                outer={i !== 1}
              />
              <Source>
                <Circle />
                {review.source}
              </Source>
            </blockquote>
          </Item>
        ))}
      </ul>
    </Section>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Section = styled.section`
  ${container}
  padding-top: ${p => p.theme.s7};
  padding-bottom: ${p => p.theme.s8};
  max-width: 55em;

  ${media.md`
    padding-top: ${p => p.theme.s8};
    padding-bottom: ${p => p.theme.s9};
  `}
`

const Item = styled.li`
  padding-top: ${p => p.theme.s7};

  ${p =>
    p.inner &&
    css`
      ${media.sm`
        margin-left: auto;
        width: 85%;
      `}

      ${media.md`
        margin-left: ${p => p.theme.s10};
        width: 70%;
      `}

      ${media.lg`
        margin-left: ${p => p.theme.s11};
        width: 65%;
      `}
    `}
`

const Quote = styled.p`
  line-height: 1.3;
  font-size: 2.25rem;
  font-weight: 700;
  font-style: italic;
  max-width: 48rem;

  ${media.sm`
    font-size: ${p => p.theme.f7};
  `}

  ${p =>
    p.outer &&
    css`
      font-size: 1.75rem;

      ${media.sm`
        font-size: ${p => p.theme.f8};
      `}
    `}
`

const Source = styled.cite`
  display: flex;
  align-items: center;
  margin-top: ${p => p.theme.s2};
  margin-left: ${p => p.theme.s4};
  font-style: normal;
`

const Circle = styled(CircleSVG)`
  flex: none;
  margin-right: ${p => p.theme.s2};
  height: 0.6em;
  width: 0.6em;
  color: ${p => p.theme.orange};
`

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import styled, { css } from 'styled-components'

import useHomeData from '../data/useHomeData'
import { SrText } from './elements'
import { ReactComponent as CircleSVG } from '../svg/circle-solid.svg'
import { container, media } from '../styles'

export default HomeReviews
