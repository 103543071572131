/*

A variation of expandMachine for "read more" sections that:

1. Increase the number of visible paragraphs on large screens, and 
2. Never return to the collapsed state after reaching the expanded state

*/

export const readMoreMachine = Machine(
  {
    id: 'readMoreMachine',
    context: {
      limit: 2, // update default externally
      limitsByScreen: { lg: 3, xs: 2 }, // update defaults externally
      screen: 'xs', // update default externally
    },
    initial: 'collapsed',
    states: {
      collapsed: {
        onEntry: 'setLimitByScreen',
        on: {
          RECALCULATE_LIMIT: 'collapsed',
          EXPAND: 'expanded',
        },
      },

      expanded: {},
    },
  },
  {
    actions: {
      setLimitByScreen: (ctx, e) => setLimitByScreen(ctx, e),
    },
  }
)

///////////////////////////////////////////////////////////////////////////////////

function setLimitByScreen(ctx, event) {
  // If triggered by resizing the viewport, update the screen value in context
  if (event.screen) ctx.screen = event.screen

  // In any case, set the limit based on the screen value now in context
  ctx.limit = ctx.limitsByScreen[ctx.screen]
}

///////////////////////////////////////////////////////////////////////////////////

export function useRecalculateLimit(state, send) {
  // const sm = useMediaQuery(`(min-width: 36em)`)
  // const md = useMediaQuery(`(min-width: 48em)`)
  const lg = useMediaQuery(`(min-width: 62em)`)
  // const xl = useMediaQuery(`(min-width: 75em)`)

  function recalculateLimit() {
    let screen = `xs`
    // if (sm && `sm` in state.context.limitsByScreen) screen = `sm`
    // if (md && `md` in state.context.limitsByScreen) screen = `md`
    if (lg && `lg` in state.context.limitsByScreen) screen = `lg`
    // if (xl && `xl` in state.context.limitsByScreen) screen = `xl`

    if (screen !== state.context.screen) {
      send({ type: `RECALCULATE_LIMIT`, screen: screen })
    }
  }

  useEffect(() => recalculateLimit(), [lg])
}

///////////////////////////////////////////////////////////////////////////////////

import { useEffect } from 'react'
import { Machine } from 'xstate'

import useMediaQuery from './useMediaQuery'
