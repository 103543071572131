// See: https://www.gatsbyjs.org/blog/2019-02-20-introducing-use-static-query/

function useHomeData() {
  const { homeYaml } = useStaticQuery(
    graphql`
      query {
        homeYaml {
          navLinks {
            href
            text
          }

          backgroundImage {
            file {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
          }

          reviews {
            quote
            source
          }

          about {
            image {
              file {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt
            }

            heading {
              normal
              orange
              pronunciation {
                en
                ipa
              }
            }
            subheading
            paragraphs
          }


          portfolio {
            heading {
              normal
              orange
              pronunciation {
                en
                ipa
              }
            }
          }

          calendar {
            images {
              file {
                childImageSharp {
                  fluid(maxWidth: 370) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt
              transform
              width
              aspectRatio {
                width
                height
              }
              objPos
            }

            heading {
              normal
              orange
              pronunciation {
                en
                ipa
              }
            }
          }
        }
      }
    `
  )

  return homeYaml
}

///////////////////////////////////////////////////////////////////////////////////

import { useStaticQuery, graphql } from 'gatsby'

export default useHomeData

/*

import useHomeData from '../data/useHomeData'

const {navLinks, reviews, about} = useHomeData()

*/
