function Heading({ heading }) {
  return (
    <Header>
      <h2>
        <Normal dangerouslySetInnerHTML={{ __html: heading.normal }} />
        <Orange>{heading.orange}</Orange>
      </h2>
      <Pronunciation>
        <English>{heading.pronunciation.en}</English> / {heading.pronunciation.ipa}
      </Pronunciation>
    </Header>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Header = styled.header`
  grid-area: heading;
  font-family: ${p => p.theme.headingFont};
`

const Normal = styled.span`
  padding-right: ${p => p.theme.s2};
  font-size: ${p => p.theme.f7};
  text-transform: uppercase;
`

const Orange = styled.span`
  display: block;
  transform: translateX(-0.15rem);
  line-height: 1.1;
  font-size: ${p => p.theme.f9};
  text-transform: uppercase;
  color: ${p => p.theme.orange};

  @media only screen and (min-width: 375px) {
    font-size: 3.5rem;
  }

  ${media.sm`
    font-size: ${p => p.theme.f10};
  `}
`

const Pronunciation = styled.p`
  padding-top: ${p => p.theme.s1};
  font-size: ${p => p.theme.f5};
  font-weight: 700;
`

const English = styled.span`
  text-transform: uppercase;
`

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import styled from 'styled-components'

import { media } from '../styles'

export default Heading
