// See: https://www.gatsbyjs.org/blog/2019-02-20-introducing-use-static-query/

function useCalendarData() {
  const { allCalendarYaml } = useStaticQuery(
    graphql`
      query {
        allCalendarYaml {
          edges {
            node {
              title {
                text
                lang
              }
              company
              details
              category
              dates {
                month
                days
              }
              link
            }
          }
        }
      }
    `
  )

  return allCalendarYaml.edges
}

///////////////////////////////////////////////////////////////////////////////////

import { useStaticQuery, graphql } from 'gatsby'

export default useCalendarData

/*

import useCalendarData from '../data/useCalendarData'

const events = useCalendarData()

*/
