function HomeHero() {
  const { navLinks } = useHomeData()
  const { backgroundImage } = useHomeData()

  function scrollToSection(e, id) {
    e.preventDefault()
    scrollToId(id)
  }

  return (
    <Header id="top">
      <SkipNav href="#main-content" />
      <MobileNav aria-label="Links to sections on the current page">
        <ul>
          {navLinks.map(link => (
            <Item key={link.href}>
              <ScrollLink
                href={link.href}
                onClick={e => scrollToSection(e, link.href)}
              >
                {link.text}
              </ScrollLink>
            </Item>
          ))}
        </ul>
      </MobileNav>

      <BackgroundImage
          fluid={backgroundImage.file.childImageSharp.fluid}
          alt={backgroundImage.alt}
          critical={true}
        />
      

      <Nav>
        <ul>
          <Item>
            <ScrollLink
              href="#calendar"
              onClick={e => scrollToSection(e, `#calendar`)}
            >
              Calendar
            </ScrollLink>
          </Item>
          <Item>
            <ScrollLink
              href="#contact"
              onClick={e => scrollToSection(e, `#contact`)}
            >
              Contact
            </ScrollLink>
          </Item>
          <Item>
            <ScrollLink
              href="#portfolio"
              onClick={e => scrollToSection(e, `#portfolio`)}
            >
              Portfolio
            </ScrollLink>
          </Item>
          <Item>
            <ScrollLink 
              href="#about" 
              onClick={e => scrollToSection(e, `#about`)}
            >
              About
            </ScrollLink>
          </Item>
        </ul>
      </Nav>

      <Heading>
        <CenteredLogo aria-hidden="true" />
        <Logo aria-hidden="true" />
        <SrText>Alaina Viau, Stage Director &amp; Creator</SrText>
      </Heading>

      <Subhead>
        <Paragraph>
          "Alaina Viau will make a strong impact on the performing arts in Canada"
        </Paragraph>
        <Credit>
        Crow’s Theatre national RBC Rising Star Emerging Directors Prize (finalist)
        </Credit>
        <Sub>
          <Circle />
            Director-in-Residence at The National Theatre School of Canada 
          <Circle />
        </Sub>
      </Subhead>
    </Header>

    
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Header = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  padding-top: ${p => p.theme.s4};
  height: 100vh;

  ${media.md`
    padding-top: ${p => p.theme.s6};
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  `}
`

const BackgroundImage = styled(Image)`
  position: absolute !important;
  z-index: -1 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Subhead = styled.div`
  width: 100%;
  font-family: ${p => p.theme.bodyFont};
  text-align: center;
  font-size: 1.45rem;
  font-weight: ${p => p.theme.fw2};
  display: none;
  ${media.md`
    display: block;
  `}
`

const Paragraph = styled.p`
  color: white;
  font-size: ${p => p.theme.f5};
  letter-spacing: 1px;
  
`

const Credit = styled.p`
  color: ${p => p.theme.orange};
  margin-top: 20px;
  font-style: italic;
  font-size: ${p => p.theme.f3};
`

const Sub = styled.p`
  color: white;
  font-style: italic;
  font-size: ${p => p.theme.f3};
`

const Circle = styled(CircleSVG)`
  flex: none;
  margin-right: ${p => p.theme.s2};
  margin-left: ${p => p.theme.s2};
  height: 0.5em;
  width: 0.5em;
  color: ${p => p.theme.orange};
`

const MobileNav = styled.nav`
  ${media.md`
    display: none;
  `}
`

const Item = styled.li`
  text-align: center;

  ${media.md`
    display: inline-flex;
    margin: 0 ${p => p.theme.s6};
  `}
`

const ScrollLink = styled(Link)`
  ${underlineThatGrowsOnHover}

  margin-top: ${p => p.theme.s1};
  font-family: ${p => p.theme.headingFont};
  font-stretch: condensed;
  font-size: ${p => p.theme.f4};
  font-weight: ${p => p.theme.fw2};
  text-transform: uppercase;
  color: white;

  &:after {
    left: 50%;
    transform: translateX(-50%)
  }
`

const Heading = styled.h1`
  max-width: 45rem;
  
  ${media.md`
    width: 21rem;
    margin-top: 17vh;
    margin-bottom: 40px;
  `}

  ${media.xl`
    margin-top: 33vh;
  `}
`

const CenteredLogo = styled(CenteredLogoSVG)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: ${p => p.theme.s10};

  width: 80%;

  @media only screen and (min-width: 375px) {
    padding-top: ${p => p.theme.s11};
    padding-bottom: ${p => p.theme.s4};
  }

  ${media.md`
    display: none;
  `}
`

const Logo = styled(LogoSVG)`
    display: none;

  ${media.md`
    display: block;
  `}
`

const Nav = styled.nav`
  display: none;

  ${media.md`
    display: block;
    width: 100%;
    left: 2rem;
    text-align: center;
  `};

  ${media.xl`
    left: 4rem;
  `}
`


///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'

import { Link, SkipNav, SrText } from './elements'
import scrollToId from '../logic/scrollToId'
import useHomeData from '../data/useHomeData'
import { ReactComponent as CenteredLogoSVG } from '../svg/logo-centered.svg'
import { ReactComponent as LogoSVG } from '../svg/logo-orange.svg'
import { ReactComponent as CircleSVG } from '../svg/circle-solid.svg'
import { container, media, underlineThatGrowsOnHover } from '../styles'

export default HomeHero
