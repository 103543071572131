function HomeCalendar() {
  const {
    calendar: { images, heading }
  } = useHomeData()
  const events = useCalendarData()

  const eventsMachine = filterAndLimitMachine.withContext({
    ...filterAndLimitMachine.context,
    category: '2022',
    limitsByScreen: { sm: 5, xs: 3 }
  })
  const [state, send] = useMachine(eventsMachine)
  useRecalculateLimit(state, send)

  const eventsInCategory = useMemo(
    () => filterItemsByCategory(state, events),
    [state, events]
  )

  const visibleEvents = useMemo(() => limitItems(state, eventsInCategory), [
    state,
    eventsInCategory
  ])

  const limited = useMemo(() => state.context.limit < eventsInCategory.length, [
    state.context.limit,
    eventsInCategory.length
  ])

  return (
    <Section id="calendar">
      <aside>
        <Images>
          {images.map(image => (
            <li key={image.alt}>
              <FloatingImage
                fluid={{
                  ...image.file.childImageSharp.fluid,
                  aspectRatio: image.aspectRatio.width / image.aspectRatio.height
                }}
                alt={image.alt}
                style={{
                  transform: image.transform,
                  width: image.width,
                  maxWidth: image.maxWidth
                }}
                imgStyle={{
                  objectPosition: image.objPos
                }}
              />
            </li>
          ))}
        </Images>
      </aside>

      <Heading heading={heading} />

      <Filters category={state.context.category} send={send} />

      {visibleEvents && (
        <Events
          visibleEvents={visibleEvents}
          limited={limited}
          previousLimit={state.context.previousLimit}
        />
      )}

      {limited && (
        <ViewAll onClick={() => send('VIEW_ALL')} aria-expanded={false}>
          View all {state.context.category} events
        </ViewAll>
      )}
    </Section>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Section = styled.section`
  ${container}
  padding-top: ${p => p.theme.s8};
  padding-right: 0;
  padding-bottom: ${p => p.theme.s8};

  ${media.md`
    padding-top: ${p => p.theme.s9};
    padding-bottom: ${p => p.theme.s9};
  `}
`

const Images = styled.ul`
  margin-left: auto;
  position: relative;
  height: 100vw;
  max-height: 34rem;
  width: 90%;
  max-width: 30rem;

  ${media.lg`
    max-height: 19rem;
  `}
`

const FloatingImage = styled(Image)`
  position: absolute !important;
  top: 0;
  right: 0;
  box-shadow: ${p => p.theme.shadow4};
`

///////////////////////////////////////////////////////////////////////////////////

function Filters({ category, send }) {
  return (
    <Toolbar role="toolbar" aria-label="filter options" aria-controls="events">
      <Filter value="2022" category={category} send={send} />
    </Toolbar>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Toolbar = styled.div`
  padding-top: ${p => p.theme.s6};
`

///////////////////////////////////////////////////////////////////////////////////

function Filter({ value, category, send }) {
  return (
    <Button
      value={value}
      onClick={() => send({ type: 'CHANGE_CATEGORY', category: value })}
      aria-pressed={category === value}
    >
      {value}
    </Button>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Button = styled.button`
  ${underlineThatGrowsOnHover}

  margin-right: ${p => p.theme.s6};
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.f6};
  font-weight: ${p => p.theme.fw2};
  text-transform: uppercase;

  &[aria-pressed='true'] {
    &:after {
      height: 0.75em;
    }
  }
`

const ViewAll = styled.button`
${underlineThatGrowsOnHover}
  margin-top: ${p => p.theme.s6};
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.f4};
  font-weight: ${p => p.theme.fw2};
  text-transform: uppercase;
`

///////////////////////////////////////////////////////////////////////////////////

function Events({ visibleEvents, limited, previousLimit }) {
  return (
    <List id="events">
      {visibleEvents.map(({ node: event }, i) => (
        <LiWithCSSAnimation
          key={event.category + event.dates[0].month + event.dates[0].days}
          style={{
            animationDelay: limited
              ? `calc(${i * 100}ms)`
              : `calc(${previousLimit * -100}ms + ${i * 100}ms)`
          }}
        >
          <div>
            <Title lang={event.title.lang}>{event.title.text}</Title>
            <Company dangerouslySetInnerHTML={{ __html: event.company }} />

            <Details>
              {event.details.map(detail => (
                <li key={detail} dangerouslySetInnerHTML={{ __html: detail }} />
              ))}
            </Details>
          </div>

          <div>
            {event.dates.map(date => (
              <p key={date.days}>
                <Month>{date.month}&nbsp;</Month>
                <Days>{date.days}</Days>
              </p>
            ))}
          </div>

          {event.link && (
            <MoreInfo href={event.link}>
              More Info
              <SrText>
                {` `}
                about {event.title.text}
              </SrText>
            </MoreInfo>
          )}
        </LiWithCSSAnimation>
      ))}
    </List>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const List = styled.ul`
  margin-top: ${p => p.theme.s6};
  background-color: ${p => p.theme.lightOrange};
`

const LiWithCSSAnimation = styled.li`
  position: relative;
  visibility: hidden;
  transform-origin: bottom;
  background-color: ${p => p.theme.lightOrange};
  padding: ${p => p.theme.s6} ${p => p.theme.s4};
  transition: background-color .2s ease-in-out;
  animation: ${enter} 1s cubic-bezier(0.5, 0, 0, 1) forwards;

  &:hover {
    background-color: ${p => p.theme.orange};
  }

  ${media.sm`
    padding-left: ${p => p.theme.s5};
  `}
  
  ${media.md`
    padding-left: ${p => p.theme.s6};
  `}

  ${media.lg`
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-column-gap: ${p => p.theme.s7};
    padding-left: ${p => p.theme.s7};
  `}
`

const Title = styled.h3`
  margin-top: ${p => p.theme.s1};
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.f6};
  transition: color 0.2s ease-in-out;

  ${LiWithCSSAnimation}:hover & {
    color: white;
  }

  ${media.lg`
    margin-top: 0;
    font-size: ${p => p.theme.f5};
  `}
`

const Company = styled.p`
  padding-top: ${p => p.theme.s1};
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.f5};
  font-weight: ${p => p.theme.fw2};
  color: ${p => p.theme.orange};
  transition: color 0.2s ease-in-out;

  ${LiWithCSSAnimation}:hover & {
    color: white;
  }

  ${media.lg`
    font-size: ${p => p.theme.f4};
  `}
`

const Details = styled.ul`
  padding-top: ${p => p.theme.s4};
  padding-left: ${p => p.theme.s5};
  line-height: ${p => p.theme.lh2};
  transition: color 0.2s ease-in-out;

  ${LiWithCSSAnimation}:hover & {
    color: white;
  }

  ${media.lg`
    padding-left: 0;
  `}
`

const Month = styled.span`
  display: inline-flex;
  padding-top: ${p => p.theme.s3};
  padding-left: ${p => p.theme.s5};
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.f5};
  font-weight: ${p => p.theme.fw2};
  transition: color 0.2s ease-in-out;

  ${LiWithCSSAnimation}:hover & {
    color: white;
  }

  ${media.lg`
    padding-top: 0;
    padding-left: 0;
  `}
`

const Days = styled.span`
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.f5};
  font-weight: ${p => p.theme.fw2};
  transition: color 0.2s ease-in-out;

  ${LiWithCSSAnimation}:hover & {
    color: white;
  }
`

const MoreInfo = styled(Link)`
  ${underlineThatGrowsOnHover}
  padding-top: ${p => p.theme.s4};
  margin-bottom: ${p => p.theme.s1};
  margin-left: ${p => p.theme.s5};
  font-family: ${p => p.theme.headingFont};
  font-weight: ${p => p.theme.fw2};
  text-transform: uppercase;
  transition: color 0.2s ease-in-out;

  ${LiWithCSSAnimation}:hover & {
    color: white;

    &:after {
      background-color: ${p => p.theme.black};
    }
  }

  ${media.lg`
    align-self: start;
    padding-top: 0;
  `}
`

///////////////////////////////////////////////////////////////////////////////////

import React, { useMemo } from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'

import Heading from './Heading'
import { Link, SrText } from './elements'
import useHomeData from '../data/useHomeData'
import useCalendarData from '../data/useCalendarData'

import useMachine from '../logic/useMachine'
import {
  filterAndLimitMachine,
  useRecalculateLimit,
  filterItemsByCategory,
  limitItems
} from '../logic/filterAndLimit'

import { container, enter, media, underlineThatGrowsOnHover } from '../styles'

export default HomeCalendar
