function HomeAbout() {
  const {
    about: { image, heading, subheading, paragraphs }
  } = useHomeData()

  const [state, send] = useMachine(readMoreMachine)
  useRecalculateLimit(state, send)

  return (
    <Section id="about">
      <PhotoWrapper>
        <Photo fluid={image.file.childImageSharp.fluid} alt={image.alt} />
        <PhotoSquare />
      </PhotoWrapper>

      <Inner>
        <Heading heading={heading} />
        <Subheading>{subheading}</Subheading>

        <VisibleParagraphs paragraphs={paragraphs.slice(0, state.context.limit)} />

        {state.value === `collapsed` && (
          <ReadMore onClick={() => send('EXPAND')} aria-expanded={false}>
            Read more<SrText> about Alaina</SrText>
          </ReadMore>
        )}

        <HiddenParagraphs
          expanded={state.value === `expanded`}
          paragraphs={paragraphs.slice(state.context.limit)}
        />
      </Inner>
    </Section>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Section = styled.section`
  ${container}
  padding-top: ${p => p.theme.s8};
  padding-right: 0;
  padding-bottom: ${p => p.theme.s8};

  ${media.md`
    padding-bottom: ${p => p.theme.s9};
  `}

  ${media.lg`
    display: grid;
    grid-template-areas: "text image";
    grid-template-columns: 4fr 3fr;
    grid-gap: 4rem;
  `}
`

const Inner = styled.div`
  grid-area: text;
  padding-right: ${p => p.theme.s4};
`

const PhotoWrapper = styled.div`
  grid-area: image;
  align-self: start;
  position: relative;
  margin-bottom: ${p => p.theme.s8};
  margin-left: auto;
  padding-right: ${p => p.theme.s6};
  max-width: 25rem;

  ${media.md`
    margin-bottom: 0;
  `}

  ${media.lg`
    margin-top: ${p => p.theme.s12};
    margin-left: 0;
  `}
`

const Photo = styled(Image)`
  position: relative;
  padding-top: ${p => p.theme.s6};
  box-shadow: ${p => p.theme.shadow5};
`

const PhotoSquare = styled.div`
  position: absolute;
  top: 10%;
  left: 15%;
  z-index: -1;
  background-color: ${p => p.theme.lightOrange};
  width: 85%;
  height: 97%;
`

const Subheading = styled.h3`
  padding-top: ${p => p.theme.s6};
  font-weight: 400;
`

///////////////////////////////////////////////////////////////////////////////////

function VisibleParagraphs({ paragraphs }) {
  return paragraphs.map(paragraph => (
    <Text key={paragraph} dangerouslySetInnerHTML={{ __html: paragraph }} />
  ))
}

///////////////////////////////////////////////////////////////////////////////////

const ReadMore = styled.button`
  ${underlineThatGrowsOnHover}
  margin-top: ${p => p.theme.s6};
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.f4};
  font-weight: ${p => p.theme.fw2};
  text-transform: uppercase;
`

///////////////////////////////////////////////////////////////////////////////////

function HiddenParagraphs({ expanded, paragraphs }) {
  return (
    <Expandable expanded={expanded}>
      {paragraphs.map(paragraph => (
        <Text key={paragraph} dangerouslySetInnerHTML={{ __html: paragraph }} />
      ))}
    </Expandable>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Text = styled.p`
  padding-top: ${p => p.theme.s5};
  ${copy}
`

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'

import Expandable from './Expandable'
import Heading from './Heading'
import { SrText } from './elements'
import useHomeData from '../data/useHomeData'
import useMachine from '../logic/useMachine'
import { readMoreMachine, useRecalculateLimit } from '../logic/readMore'
import { container, copy, media, underlineThatGrowsOnHover } from '../styles'

export default HomeAbout
