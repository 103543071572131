function Contact() {
  const { heading, socials, email, form } = useContactData()

  return (
    <Section id="contact">
      <Heading heading={heading} />
      <Grid>
        <SocialLinks links={socials} />
        <GetInTouch email={email} form={form} />
      </Grid>
    </Section>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Section = styled.section`
  ${container}
  padding-top: ${p => p.theme.s8};
  padding-right: 0;

  ${media.md`
    padding-top: ${p => p.theme.s9};
  `}
`

const Grid = styled.div`
  max-width: ${p => p.theme.s15};

  ${media.sm`
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-areas: "social-links get-in-touch";
    margin-top: ${p => p.theme.s8};
  `}
`

///////////////////////////////////////////////////////////////////////////////////

function SocialLinks({ links }) {
  return (
    <LightOrange aria-label="Links to social media profiles">
      <ul>
        {links.map((link, i) => (
          <SocialLink key={i} link={link} />
        ))}
      </ul>
    </LightOrange>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const LightOrange = styled.nav`
  grid-area: social-links;
  margin-top: ${p => p.theme.s8};
  background-color: ${p => p.theme.lightOrange};
  padding-top: ${p => p.theme.s1};
  padding-right: ${p => p.theme.s5};
  padding-bottom: ${p => p.theme.s7};
  padding-left: ${p => p.theme.s5};

  ${media.sm`
    align-self: start;
  `}

  ${media.md`
    padding-left: ${p => p.theme.s6};
  `}

  ${media.lg`
    padding-left: ${p => p.theme.s7};
  `}
`

///////////////////////////////////////////////////////////////////////////////////

function SocialLink({ link }) {
  let icon = <InstagramIcon aria-hidden="true" />
  if (link.heading === `Facebook`) icon = <FacebookIcon aria-hidden="true" />
  if (link.heading === `Twitter`) icon = <TwitterIcon aria-hidden="true" />

  return (
    <li>
      <Subheading>{link.heading}</Subheading>
      <LinkWrapper>
        <IconWrapper>{icon}</IconWrapper>
        <StyledLink href={link.href}>{link.text}</StyledLink>
      </LinkWrapper>
    </li>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
`

const IconWrapper = styled.span`
  display: inline-flex;
  margin-right: ${p => p.theme.s4};
  border-radius: ${p => p.theme.r100};
  background-color: ${p => p.theme.black};
  padding: 0.37rem;
  font-size: ${p => p.theme.f4};
  color: ${p => p.theme.lightOrange};
`

const InstagramIcon = styled(InstagramSVG)`
  ${icon}
`

const FacebookIcon = styled(FacebookSVG)`
  ${icon}
`

const TwitterIcon = styled(TwitterSVG)`
  ${icon}
`

const Subheading = styled.h3`
  margin-top: ${p => p.theme.s6};
  padding-top: ${p => p.theme.s2};
  padding-bottom: ${p => p.theme.s3};
  font-family: ${p => p.theme.headingFont};
  text-transform: uppercase;
`

const StyledLink = styled(Link)`
  ${underlineThatGrowsOnHover}
  font-weight: 700;
`

///////////////////////////////////////////////////////////////////////////////////

function GetInTouch({ email, form }) {
  return (
    <Orange>
      <Subheading>{email.heading}</Subheading>
      <EmailLink href={email.href}>{email.text}</EmailLink>
      <Subheading>{form.heading}</Subheading>
      <Text>{form.invitation}</Text>
      <NetlifyForm />
    </Orange>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Orange = styled.div`
  grid-area: get-in-touch;
  background-color: ${p => p.theme.orange};
  padding-top: ${p => p.theme.s1};
  padding-right: ${p => p.theme.s5};
  padding-bottom: ${p => p.theme.s8};
  padding-left: ${p => p.theme.s5};

  ${media.md`
    padding-right: ${p => p.theme.s6};
    padding-left: ${p => p.theme.s6};
  `}

  ${media.lg`
    padding-right: ${p => p.theme.s7};
    padding-left: ${p => p.theme.s7};
  `}
`

const EmailLink = styled(Link)`
  ${underlineThatGrowsOnHover}

  font-weight: ${p => p.theme.fw2};
  white-space: nowrap;
  color: white;

  &:after {
    background-color: ${p => p.theme.black};
  }
`

const Text = styled.p`
  ${copy}
  color: white;
`

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import styled from 'styled-components'

import Heading from './Heading'
import NetlifyForm from './NetlifyForm'
import { Link } from './elements'
import { ReactComponent as InstagramSVG } from '../svg/instagram.svg'
import { ReactComponent as FacebookSVG } from '../svg/facebook-f.svg'
import { ReactComponent as TwitterSVG } from '../svg/twitter.svg'
import useContactData from '../data/useContactData'
import { container, copy, icon, media, underlineThatGrowsOnHover } from '../styles'

export default Contact
