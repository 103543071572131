// See: https://www.gatsbyjs.org/blog/2019-02-20-introducing-use-static-query/

function usePortfolioData() {
  const { allPortfolioYaml } = useStaticQuery(
    graphql`
      query {
        allPortfolioYaml {
          edges {
            node {
              title {
                text
                lang
              }
              slug
              category
              featuredImage {
                file {
                  childImageSharp {
                    fluid(maxWidth: 700) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                alt
                objPosition
              }
              company
              venue
              description
            }
          }
        }
      }
    `
  )

  return allPortfolioYaml.edges
}

///////////////////////////////////////////////////////////////////////////////////

import { useStaticQuery, graphql } from 'gatsby'

export default usePortfolioData

/*

import usePortfolioData from '../data/usePortfolioData'

const portfolioItems = usePortfolioData()

*/
